import React from 'react'

function Nsfw2(props) {
  return (
    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M4.8 1.40006C4.54174 1.05572 4.09211 0.915267 3.68377 1.05138C3.27543 1.1875 3 1.56963 3 2.00006V10.0001C3 10.5523 3.44772 11.0001 4 11.0001C4.55228 11.0001 5 10.5523 5 10.0001V5.00006L9.2 10.6001C9.45826 10.9444 9.90789 11.0849 10.3162 10.9487C10.7246 10.8126 11 10.4305 11 10.0001V2.00006C11 1.44778 10.5523 1.00006 10 1.00006C9.44772 1.00006 9 1.44778 9 2.00006V7.00006L4.8 1.40006Z" />
      <path d="M15 1.00006C13.8954 1.00006 13 1.89549 13 3.00006V5.00006C13 6.10463 13.8954 7.00006 15 7.00006H19V9.00006H14C13.4477 9.00006 13 9.44778 13 10.0001C13 10.5523 13.4477 11.0001 14 11.0001H19C20.1046 11.0001 21 10.1046 21 9.00006V7.00006C21 5.89549 20.1046 5.00006 19 5.00006H15V3.00006H20C20.5523 3.00006 21 2.55235 21 2.00006C21 1.44778 20.5523 1.00006 20 1.00006H15Z" />
      <path d="M3 14.0001C3 13.4478 3.44772 13.0001 4 13.0001H10C10.5523 13.0001 11 13.4478 11 14.0001C11 14.5524 10.5523 15.0001 10 15.0001H5V17.0001H10C10.5523 17.0001 11 17.4478 11 18.0001C11 18.5523 10.5523 19.0001 10 19.0001H5V22.0001C5 22.5523 4.55228 23.0001 4 23.0001C3.44772 23.0001 3 22.5523 3 22.0001V14.0001Z" />
      <path d="M15 14.0001C15 13.4478 14.5523 13.0001 14 13.0001C13.4477 13.0001 13 13.4478 13 14.0001V22.0001C13 22.4495 13.2999 22.8438 13.7331 22.9638C14.1662 23.0838 14.6262 22.9 14.8575 22.5146L17 18.9437L19.1425 22.5146C19.3738 22.9 19.8338 23.0838 20.2669 22.9638C20.7001 22.8438 21 22.4495 21 22.0001V14.0001C21 13.4478 20.5523 13.0001 20 13.0001C19.4477 13.0001 19 13.4478 19 14.0001V18.3897L17.8575 16.4856C17.6768 16.1844 17.3513 16.0001 17 16.0001C16.6487 16.0001 16.3232 16.1844 16.1425 16.4856L15 18.3897V14.0001Z" />
    </svg>
  )
}

export default Nsfw2
