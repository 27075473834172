import React from 'react'

function Sfw(props) {
  return (
    <svg fill="currentColor" height="800px" width="800px" version="1.1" viewBox="0 0 512 512" {...props}>
      <g>
        <path
          d="M503.236,275.193c-9.515-24.183-55.765-72.628-55.765-85.491V65.942h-32.142v123.76
c0,28.934-128.874,70.272-159.323,156.555C225.546,259.974,96.673,218.637,96.673,189.703V65.942H64.519v123.76
c0,12.863-46.249,61.308-55.754,85.491c-26.358,67.023,8.462,142.128,81.21,162.431c108.221,30.208,166.03-30.449,166.03-30.449
s57.8,60.657,166.01,30.449C494.773,417.322,529.593,342.217,503.236,275.193z"
        />
      </g>
    </svg>
  )
}

export default Sfw
